import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import Hero from '../components/Hero'
import Video from '../components/Video'
import AboutUsCard from '../components/AboutUsCard'
import BoardMemberCard from '../components/BoardMemberCard'
import CallToAction from '../components/CallToAction'
import NumberedCard from '../components/NumberedCard'
import teamProfiles from '../content/teamProfiles'
import boardMembers from '../content/boardMembers'
import boardAdvisors from '../content/boardAdvisors'

const AboutUsPage = ({ data }) => {
  const getFullImage = (profile) => {
    const [firstName, lastName] = profile.name.split(' ')
    const id = `${firstName}${lastName.charAt(0)}`
    return getImage(data[id])
  }

  const getAvatarImage = (profile) => {
    const [firstName] = profile.name.split(' ')
    return getImage(data[`${firstName}Avatar`])
  }

  return (
    <main className="aboutUsPage">
      <Hero
        heading={
          <>
            <div>Our purpose:</div>
            <div>Better health</div>
            <div>through adherence.</div>
          </>
        }
        color="grayblue"
      />

      <div className="page-section">
        <div className="page-section-content our-mission">
          <h2>Our mission</h2>
          <p>
            When someone is prescribed medication what matters most is that they take it as intended so they can receive
            the full benefit. The reality is once they leave the doctor’s office, we don’t know what they’re going to do
            next. Or how to help them best.
            <br />
            <br />
            AdhereTech delivers solutions that make it easy for all patients to establish and maintain positive
            medication-taking behaviors leading to better health. It’s the in-home partner every medication should have.
            An elegantly simple and proven system designed to gently remind people to take their medications and
            intervene when they don’t — early enough to make a difference. Making sure not a day is lost on their path
            to better health.
            <br />
            <br />
            <strong>Because every dose — and every day — counts.</strong>
          </p>
        </div>
      </div>

      <div className="page-section--blue key-principles">
        <div className="page-section-content">
          <h2>Here's how we do it</h2>
          <p>
            To help us consistently deliver these results, we hold ourselves to 2 key principles that drive every
            decision we make:
          </p>

          <div>
            <NumberedCard number={1} header={`Making adherence effortless`}>
              <p>
                Patients with a serious disease already have enough to deal with. AdhereTech recognizes that it is
                critical to work with the patient to make adherence effortless, without adding to their burden.
                <br />
                <br />
                Aidia doesn’t require any patient setup, as each smart device is delivered pre-set to the patient’s
                personal dosing schedule. The patient just has to activate the device and the system will begin
                operating via an always-on cellular signal.
                <br />
                <br />
                By seamlessly integrating into a patient’s life, with gentle reminders and personalized support, Aidia
                gradually enables positive behavior change over time. Our centralized database and proprietary
                algorithms prompt intervention only when a patient starts to become non-adherent. These timely
                interventions escalate as needed, to ensuring that patients have the opportunity to get back on track to
                better health.
              </p>
            </NumberedCard>
            <NumberedCard number={2} header={`Ensuring patient safety & privacy`}>
              Because patient safety and privacy is essential to the way we do business, we ensure full compliance with
              all necessary legal and regulatory requirements and safety standards (including but not limited to: FDA,
              HIPAA, TCPA, CE, USP, ISO, FCC, and more).
            </NumberedCard>
          </div>
        </div>
      </div>

      <div className="page-section ted-talk">
        <h2>TED Talk: Why understanding patients is important to adherence</h2>
        <p>
          Achieving medication adherence must go beyond the use of a smart device. In this 5-minute TED Talk, AdhereTech
          Co-Founder Josh Stein discusses the top 3 principles that guided the AdhereTech approach to patient-centric
          design.
        </p>
        <Video videoSrcURL="https://www.youtube.com/embed/NwTO2_s4TN8" videoTitle="It's smart to design simple" />
      </div>

      <div className="page-section the-team">
        <h2>Get to know the team</h2>
        <div className="about-us-cards">
          <AboutUsCard profile={teamProfiles[0]} image={getFullImage(teamProfiles[0])} />
        </div>
        <div className="about-us-cards">
          {teamProfiles.slice(1).map((profile, i) => {
            return <AboutUsCard key={i} profile={profile} image={getFullImage(profile)} />
          })}
        </div>
      </div>

      <div className="page-section board-members">
        <h2>AdhereTech Board Members</h2>
        <div className="board-member-cards">
          {boardMembers.map((profile, i) => {
            return <BoardMemberCard key={i} profile={profile} image={getAvatarImage(profile)} />
          })}
        </div>
      </div>

      <div className="page-section board-advisors">
        <h2>AdhereTech Board Advisors</h2>
        <div className="board-advisor-cards">
          {boardAdvisors.map((profile, i) => {
            return <BoardMemberCard key={i} profile={profile} image={getAvatarImage(profile)} />
          })}
        </div>
      </div>

      <div className="page-section">
        <div className="page-section-content call-to-action-grid">
          <CallToAction
            color="blue"
            headerText="Interested in joining our growing team?"
            linkText="View job openings"
            linkUrl="https://boards.greenhouse.io/adheretech"
            external
          />
        </div>
      </div>
    </main>
  )
}

export default AboutUsPage

export const pageQuery = graphql`
  query {
    RamyE: file(relativePath: { eq: "headshots/RamyHeadshot.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 250, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
    InnesM: file(relativePath: { eq: "headshots/Innes Meldrum Headshot.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 250, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
    JoshS: file(relativePath: { eq: "headshots/Josh Stein.WebHeadshot.July2021.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 250, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
    JohnL: file(relativePath: { eq: "headshots/John Langhauser.WebHeadshot.July2021.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 250, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
    JeremyG: file(relativePath: { eq: "headshots/Jeremy Gilbert.WebHeadShot.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 250, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
    WhitneyS: file(relativePath: { eq: "headshots/WhitneyHeadshot.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 250, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
    NealeAvatar: file(relativePath: { eq: "headshots/neale.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 68, height: 68, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
    DanAvatar: file(relativePath: { eq: "headshots/dan.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 68, height: 68, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
    ElliotAvatar: file(relativePath: { eq: "headshots/elliot.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 68, height: 68, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
    PaulAvatar: file(relativePath: { eq: "headshots/paul.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 68, height: 68, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
    JoshAvatar: file(relativePath: { eq: "headshots/josh.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 68, height: 68, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
    JohnAvatar: file(relativePath: { eq: "headshots/john.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 68, height: 68, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
    InnesAvatar: file(relativePath: { eq: "headshots/innes.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 68, height: 68, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
    MarkAvatar: file(relativePath: { eq: "headshots/mark.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 68, height: 68, placeholder: NONE, layout: FIXED, quality: 100, formats: [AUTO])
      }
    }
  }
`
