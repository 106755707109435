export default [
  {
    name: `Innes Meldrum`,
    title: `Chief Executive Officer`,
    careerDriver: `Championing disruptive change to broadly impact patient health outcomes through collaborative and purpose-driven leadership.`,
    bio: `With 30 years of leadership experience in both technology and pharmaceuticals, Innes Meldrum brings an
    authentic, charismatic, cross-cultural leadership approach to successfully deliver business growth through
    highly engaged organizations.<BR><BR>
    Innes comes to AdhereTech from the Clinical Biotech space and prior to that was the CCO for
    Otsuka North America where he had responsibility for all commercial aspects of Otsuka's multi-billion
    dollar pharma portfolio.
    <BR><BR>
    With broad experience both globally and locally, at large and small companies in the Pharma space (Novartis, Orexo, Otsuka and Reset) and prior to this
    within the Technology space (Nortel, HP and Agilent Technologies), Innes has a background ideally suited to
    leading Adheretech.
    <BR><BR>
    He has a BEng in Electrical and Electronic engineering from the University of Strathclyde in Scotland.
    <BR><BR>
    Innes enjoys working out, reading, golfing, has a qualification in Single Malt Scotch and loves Karaoke and Haggis.
    `,
    linkedin: `https://www.linkedin.com/in/innesmeldrum/`,
  },
  {
    name: `Jeremy Gilbert`,
    title: `Chief Product Development Officer`,
    careerDriver: `Leading teams that create and bring to market cutting-edge medical technologies that will help people live longer, healthier lives.`,
    bio: `Jeremy Gilbert is Chief Product Development Officer for AdhereTech. He joined the company in 2021, bringing extensive leadership expertise spanning the healthcare industry. At AdhereTech, Jeremy leads the teams that drive the lifecycle of new products including conception, design, technical development, go-to-market strategy, and launch. <BR><BR>Jeremy previously drove product and technology for health technology companies in his roles as Chief Product Officer for Pear Therapeutics and as Vice President of Product and Strategy for PatientsLikeMe. <BR><BR>Jeremy holds a BA in Computer Science from Brandeis University, as well as an MBA from the MIT Sloan School of Management.`,
    linkedin: `https://www.linkedin.com/in/jeremygilbert`,
  },
  {
    name: `Ramy Elsisi`,
    title: `Chief Financial Officer`,
    careerDriver: `Transforming financial and operational results to maximize enterprise and shareholder value.`,
    bio: `Ramy brings more than 20 years of experience as a strategic finance executive, enterprise leader, and operational business partner in the pharmaceutical and consumer health industries. Most recently he was the Head of Commercial Finance at Otsuka Pharmaceuticals North America.
    <BR><BR>
    Previously, he held numerous senior positions of increasing responsibility over 15 years at Merck, Schering Plough and Bayer serving as CFO of Latin America, Canada and Western Europe, Global Head of Upper Respiratory /Allergy and Cough & Cold business units, and Head of Global R&D and strategic planning.
    <BR><BR>
    Prior to that, he led the Financial Planning & Analysis groups at Johnson & Johnson, and Mercedes-Benz USA. 
    <BR><BR>
    In addition, Ramy’s advisory experience spans a wide range of projects for start-up, public, and private companies in the life sciences and consumer health industries. 
    <BR><BR>
    Ramy holds an MBA in Finance and International Business, and a BS in Finance and Accounting from Ramapo College of NJ.`,
    linkedin: `https://www.linkedin.com/in/ramy-e-elsisi/`,
  },
  {
    name: `Whitney Salamone`,
    title: `Vice President of Commercial Operations`,
    careerDriver: `Leveraging innovative and digital health solutions to enact positive behavior change in at-risk populations.`,
    bio: `Currently serving as the Vice President of Commercial Operations at AdhereTech, Whitney plays a pivotal role in shaping the company's commercial strategy. Her primary focus is on implementing successful solutions for medication adherence in partnering organizations. Whitney's dedication and expertise position her as a key player in AdhereTech's commitment to improving medication adherence through cutting-edge solutions.
    <BR><BR>
    Previously, Whitney's work was focused in the mental health and addiction treatment sectors, with a diverse background that showcases her expertise. At Advocates for Human Potential, she played a vital role as a member of SAMHSA’s Behavioral Health HIV/AIDS Technical Assistance Center, providing technical assistance in substance abuse prevention with a focus on HIV/AIDS and Hepatitis C. Transitioning into the digital health space, Whitney contributed significantly to <a href="http://ginger.io/" target="_blank" rel="noreferrer">Headspace for Organizations | Formerly Headspace Health and Ginger</a>, now part of Headspace Health, as an original health coach and platform contributor for mental health guidance. Her journey continued at American Addiction Centers, where she excelled as a Reputation Management Specialist, overseeing internal and external reputation efforts and leading review acquisition strategies.
    <BR><BR>
    Whitney holds a master’s degree in public health from Boston University along with a BA from SUNY Geneseo in psychology.`,
    linkedin: `https://www.linkedin.com/in/whitney-salamone-mph-7687a8b0`,
  },
  {
    name: `John Langhauser`,
    title: `Co-Founder, Chief Operating Officer and Chief Information Security Officer`,
    careerDriver: `Building easy-to-use technology that connects patients to high quality care.`,
    bio: `John Langhauser is the technical cofounder and former Chief Technology Officer at AdhereTech. In his current role as Chief Operating Officer, he partners with senior leaders to ensure that the patient-first, customer-first ideals AdhereTech was founded upon continue to be reflected in every product launch, patient interaction and customer experience. <BR><BR> As cofounder, John was directly responsible for early software and product development, strategic product roadmapping and corporate governance. Throughout the growth of AdhereTech, he has focused on product development, process improvement, and quality control. Prior to cofounding AdhereTech in 2012 John was a software engineer at Bridgewater Associates. <BR><BR>John holds an MS in Computer Science and a BS in Electrical Engineering and Computer Science from Yale.`,
    linkedin: `https://www.linkedin.com/in/john-langhauser-3542673a`,
  },
]
